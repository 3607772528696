import { useState, useEffect } from "react";
import { api } from "../helpers";

function isGamepadPressed(gamepadIndex) {
  const gamepad = navigator.getGamepads()[gamepadIndex];

  return gamepad.buttons.some((button) => button.pressed);
}

async function getIsWinner() {
  const response = await window.fetch(`${api}/winners`);

  if (!response.ok) {
    const responseText = await response.text();
    throw new Error(responseText);
  }

  const { winner } = await response.json();

  return winner;
}

function Gamepad({ element, isVisible, setActiveSlide }) {
  const { style } = element;
  const [gamepadIndex, setGamepadIndex] = useState(null);
  const [isActive, setActive] = useState(true);
  const [mainSlideIndex, setMainSlideIndex] = useState(null);

  useEffect(() => {
    window.addEventListener("gamepadconnected", (event) => {
      console.log("gamepad connected");

      setGamepadIndex(event.gamepad.index);
    });

    window.addEventListener("gamepaddisconnected", (e) => {
      console.log("gamepad disconnected");
    });
  }, []);

  useEffect(() => {
    if (isVisible) setActive(true);
  }, [isVisible]);

  useEffect(() => {
    const TARGET_SLIDE = {
      FIRST_VIDEO: 1,
      LAST_VIDEO: 3,
      PRIZE: 4,
    };
    let intervalId;

    if (!isActive) {
      clearInterval(intervalId);
      return;
    }

    if (gamepadIndex === null) return;

    intervalId = setInterval(async () => {
      if (isGamepadPressed(gamepadIndex)) {
        const isWinner = await getIsWinner();
        let targetSlide;
        let nextMainSlideIndex;

        if (isWinner) {
          targetSlide = TARGET_SLIDE.PRIZE;
          nextMainSlideIndex = null;
        } else if (
          !mainSlideIndex ||
          mainSlideIndex === TARGET_SLIDE.LAST_VIDEO
        ) {
          targetSlide = TARGET_SLIDE.FIRST_VIDEO;
          nextMainSlideIndex = TARGET_SLIDE.FIRST_VIDEO;
        } else {
          targetSlide = mainSlideIndex + 1;
          nextMainSlideIndex = mainSlideIndex + 1;
        }

        clearInterval(intervalId);
        setActive(false);
        setActiveSlide(targetSlide);
        setMainSlideIndex(nextMainSlideIndex);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [gamepadIndex, isActive, mainSlideIndex, setActiveSlide]);

  return <div style={style} />;
}

export default Gamepad;
