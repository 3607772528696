import { useEffect, useRef, useState } from "react";
import { startCameraVideo } from "helpers";
import {
  setFaceLandmarker,
  getCanvasStyles,
  getSantaMask,
  getSantaMaskStyles,
} from "helpers/faceTracker";

import "./FaceTrackerElement.css";

function FaceTrackerElement({ element, player = {}, faceTrackerEnabled, setFaceTrackerEnabled }) {
  const { style, rotation = -90 } = element;
  const videoRef = useRef();
  const canvasRef = useRef();
  const filterTimeoutsIdsRef = useRef([]);
  const isFilterActiveRef = useRef(false);
  const [isCameraEnabled, setCameraEnabled] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.resetTransform();
    const DETECTION_TIME = 0.5;

    function drawSantaMask(landmarks) {
      const img = new Image();

      img.src = getSantaMask(rotation);
      ctx.drawImage(img, ...getSantaMaskStyles(canvas, landmarks, rotation));
    }

    function drawVideo() {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    }

    function cleanCanvas() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    function setDelayedFilterActive() {
      const filterTimeoutId = setTimeout(() => {
        isFilterActiveRef.current = true;
      }, DETECTION_TIME * 1000);

      filterTimeoutsIdsRef.current.push(filterTimeoutId);
    }

    function removeFilterActive() {
      filterTimeoutsIdsRef.current.forEach((filterTimeoutId) =>
        clearTimeout(filterTimeoutId)
      );
      filterTimeoutsIdsRef.current = [];
      isFilterActiveRef.current = false;
    }

    function handleFaceDetection(faceLandmarker) {
      const results = faceLandmarker.detectForVideo(video, performance.now());
      const isFaceDetected = !!results.faceLandmarks.length;

      if (isFaceDetected) {
        setDelayedFilterActive();
      } else {
        removeFilterActive();
      }

      cleanCanvas();
      drawVideo();

      if (isFilterActiveRef.current) drawSantaMask(results.faceLandmarks[0]);

      if (isFilterActiveRef.current && !faceTrackerEnabled) setFaceTrackerEnabled(true);
    }

    function renderLoop(faceLandmarker, lastVideoTime = -1) {
      if (video.currentTime !== lastVideoTime) {
        handleFaceDetection(faceLandmarker);
        lastVideoTime = video.currentTime;
      }

      requestAnimationFrame(() => {
        renderLoop(faceLandmarker, lastVideoTime);
      });
    }

    async function setCanvasDimensions() {
      const { videoWidth, videoHeight } = video;

      canvas.width = style.height;
      canvas.height = (videoWidth / videoHeight) * style.width;
    }

    async function init() {
      try {
        await startCameraVideo(video);
        setCameraEnabled(true);
        await setCanvasDimensions();

        const faceLandmarker = await setFaceLandmarker();
        renderLoop(faceLandmarker);
      } catch (error) {
        setCameraEnabled(false);
      }
    }

    init();
  }, [rotation, style]);

  if (!isCameraEnabled) {
    return (
      <div style={style} className="no-camera">
        <p>Camera disabled</p>
      </div>
    );
  }

  return (
    <div style={style}>
      <video ref={videoRef} />
      <canvas className="face-tracker-canvas" style={getCanvasStyles(rotation)} ref={canvasRef} />
    </div>
  );
}

export default FaceTrackerElement;
