export default function startCameraVideo(camera) {
  if (navigator?.mediaDevices.getUserMedia) {
    return navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        camera.srcObject = stream;
        return camera.play();
      })
      .catch((error) => {
        console.error("Error accessing the camera:", error);
        throw error;
      });
  } else {
    console.error("MediaDevices API is not supported in this browser");
  }
}
