import { useState, useRef, useEffect } from 'react';
import {
  getCanvasStyles,
} from "helpers/faceTracker";

const downloadImage = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result)
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error downloading and converting image:', error);
  }
};

function isCanvasBlank(canvas) {
  return !canvas.getContext('2d')
    .getImageData(0, 0, canvas.width, canvas.height).data
    .some(channel => channel !== 0);
}

function ScreenshotElement({ element }) {
  const canvasRef = useRef(null);
  const { style, url, rotation = -90, config = {} } = element;
  const { canvas = {} } = config

  if (canvasRef.current && isCanvasBlank(canvasRef.current)) {
    downloadImage(url).then(blob => {
      const context = canvasRef.current.getContext('2d');
      const img = new Image();
      img.src = blob;
      img.onload = () => { 
        context.drawImage(img, 0, 0); 
      };
    })
  }

  return (<div width={style.width} height={style.height} >
    <canvas className={`screenshot-canvas screenshot-canvas--${canvas.class}`} ref={canvasRef} width={style.width} height={style.height}></canvas>
  </div>);
}

export default ScreenshotElement;
