import { useRef, useEffect } from "react";
import { startCameraVideo } from "helpers";
import { getStyles } from "helpers/cameraFilterInput";

function CameraWithBackgroundVideo({
  element,
  playing,
  visible,
  player,
  creativeState,
  setCreativeState,
  setActiveSlide,
}) {
  const { style, loop, config } = element;
  const { preload = "none" } = player || {};
  const cameraRef = useRef();
  const videoEl = useRef(null);

  useEffect(() => {
    if (playing && visible) {
      const camera = cameraRef.current;

      startCameraVideo(camera);
    }
  }, [
    config,
    playing,
    visible,
    creativeState,
    setActiveSlide,
    setCreativeState,
  ]);

  useEffect(() => {
    if (playing && visible) {
      videoEl.current.play();
      videoEl.current.addEventListener("ended", () => {
        setActiveSlide(0);
      });
    } else {
      videoEl.current.pause();
      videoEl.current.currentTime = 0;
    }
  }, [playing, visible, setActiveSlide]);

  return (
    <div style={style}>
      <video
        ref={videoEl}
        width={style.width}
        height={style.height}
        preload={preload}
        muted
        style={{ position: "absolute", zIndex: 2 }}
      >
        <source
          src="https://www.ccplay.es/campaigns/assets/squid-game.webm"
          type="video/webm"
        />
      </video>
      <video
        style={{ ...getStyles(config.rotation, config.mirror, style) }}
        ref={cameraRef}
      />
    </div>
  );
}

export default CameraWithBackgroundVideo;
