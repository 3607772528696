import * as faceapi from "face-api.js";
import { FilesetResolver, FaceLandmarker } from "@mediapipe/tasks-vision";
import {
  SantaMask,
  SantaMaskRotatedClockwise,
  SantaMaskRotatedAntiClockwise,
} from "assets/cocacola";

export async function loadFaceApiModels() {
  await faceapi.nets.ssdMobilenetv1.loadFromUri("/face-api/models");
}

export async function getDetection(canvas) {
  const detections = await faceapi.detectAllFaces(
    canvas,
    new faceapi.SsdMobilenetv1Options({ minConfidence: 0.3 })
  );

  return detections[detections.length - 1];
}

function getContentCanvasPosition(mirror, rotation, elementSizes, canvasSizes) {
  if (rotation === 90) {
    return {
      dx: 0,
      dy: mirror
        ? -canvasSizes.height / 2 + elementSizes.width / 2
        : -canvasSizes.height / 2 - elementSizes.width / 2,
    };
  }

  if (rotation === -90) {
    return {
      dx: -elementSizes.height,
      dy: mirror ? -elementSizes.width : 0,
    };
  }

  return {
    dx: mirror
      ? -(canvasSizes.width / 2 + elementSizes.width / 2)
      : -(canvasSizes.width / 2 - elementSizes.width / 2),
    dy: 0,
  };
}

function getContentCanvasDimensions(elementSizes, videoSizes, rotation) {
  if (rotation) {
    return {
      width: elementSizes.height,
      height: (videoSizes.height * elementSizes.height) / videoSizes.width,
    };
  }

  return {
    width: (videoSizes.width * elementSizes.height) / videoSizes.height,
    height: elementSizes.height,
  };
}

function getCanvasAttributes(mirror, rotation, elementSizes, videoSizes) {
  const canvasSizes = getContentCanvasDimensions(
    elementSizes,
    videoSizes,
    rotation
  );
  const { width, height } = canvasSizes;

  const { dx, dy } = getContentCanvasPosition(
    mirror,
    rotation,
    elementSizes,
    canvasSizes
  );

  return [dx, dy, width, height];
}

export function getMarkStyles(detectedBox, elementHeight) {
  const detectedBoxBottom = elementHeight - detectedBox.bottom;
  const topSeparation = detectedBox.height * 0.5;
  const markWidth = detectedBox.width;

  return {
    bottom: detectedBoxBottom + detectedBox.height + topSeparation,
    left: detectedBox.left + detectedBox.width / 2 - markWidth / 2,
    width: markWidth,
  };
}

export function drawCanvas({ canvas, video, mirror, rotation, style }) {
  const ctx = canvas.getContext("2d");

  const { clientWidth, clientHeight } = video;
  const videoSizes = {
    width: clientWidth,
    height: clientHeight,
  };

  ctx.setTransform(1, 0, 0, 1, 0, 0);

  if (mirror) {
    ctx.scale(-1, 1);
  }

  ctx.rotate((rotation * Math.PI) / 180);

  ctx.drawImage(
    video,
    ...getCanvasAttributes(mirror, rotation, style, videoSizes)
  );
}

export function hasBoxChangedSize(box, markStyles, styleHeight) {
  const MINIMUM_SIZE_TO_CHANGE = 20;

  return (
    Math.abs(markStyles.width - getMarkStyles(box, styleHeight).width) >
    MINIMUM_SIZE_TO_CHANGE
  );
}

export async function logDetection(detection, sendLog, bsPlayerId) {
  try {
    sendLog(
      `Face detected ${JSON.stringify(detection)} on player ${bsPlayerId}`
    );
  } catch (error) {
    sendLog(`Error parsing face detection`);
  }
}

export function getMarksOpacity(noDetection, transitionDone) {
  if (noDetection) {
    return { markOpacity: 0, markTransitionOpacity: 1 };
  }

  return {
    markOpacity: transitionDone ? 1 : 0,
    markTransitionOpacity: transitionDone ? 0 : 1,
  };
}

export function getContainerClassName(noDetection) {
  return ["mark-container", noDetection ? "filter-on" : "filter-off"];
}

export async function setFaceLandmarker() {
  const filesetResolver = await FilesetResolver.forVisionTasks(
    "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
  );

  const faceLandmarker = await FaceLandmarker.createFromOptions(
    filesetResolver,
    {
      baseOptions: {
        modelAssetPath:
          "https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task",
      },
      outputFaceBlendshapes: true,
      runningMode: "VIDEO",
      numFaces: 1,
    }
  );

  await faceLandmarker.setOptions({ runningMode: "VIDEO" });

  return faceLandmarker;
}

export function getCanvasStyles(rotation) {
  if (rotation === 90)
    return {
      width: 1920,
      height: 1080,
      transformOrigin: "top left",
      transform: "rotate(-90deg) scale(-1, 1)",
    };

  if (rotation === -90)
    return {
      width: 1920,
      height: 1080,
      transformOrigin: "top left",
      transform: "rotate(90deg) translate(1920px, -1080px) scale(-1, 1)",
    };
}

export function getSantaMask(rotation) {
  if (rotation === -90) return SantaMaskRotatedClockwise;
  if (rotation === 90) return SantaMaskRotatedAntiClockwise;
  if (rotation === 0) return SantaMask;
}

export function getSantaMaskStyles(canvas, landmarks, rotation) {
  const left = landmarks[234];
  const right = landmarks[454];
  const top = landmarks[10];
  const bottom = landmarks[152];
  const leftX = left.x * canvas.width;
  const leftY = left.y * canvas.height;
  const rightY = right.y * canvas.height;
  const topX = top.x * canvas.width;
  const topY = top.y * canvas.height;
  const bottomX = bottom.x * canvas.width;
  const maskWidth = (rightY - leftY) * 2.8;
  const maskHeight = (topX - bottomX) * 2.5;

  if (rotation === 90) {
    return [
      bottomX - maskHeight * 0.26,
      leftY - maskWidth * 0.25,
      maskHeight,
      maskWidth,
    ];
  }

  if (rotation === -90) {
    return [
      topX + maskHeight * 0.33,
      rightY + maskWidth * 0.39,
      -maskHeight,
      -maskWidth,
    ];
  }

  return [
    leftX - maskWidth * 0.23,
    topY - maskHeight * 0.35,
    maskWidth,
    maskHeight,
  ];
}
