/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useCallback } from "react";
import { startCameraVideo } from "helpers";
import * as faceapi from "face-api.js";
import { useLogger } from "providers/LoggerProvider";
// import axios from "axios";
// import { nanoid } from "nanoid";

function FaceDetectionElement({
  element,
  player = {},
  isVisible,
  setActiveSlide,
}) {
  const videoRef = useRef();
  const { sendLog } = useLogger();
  const { style } = element;
  const intervalId = useRef();

  const tracking = useCallback(
    async (video) => {
      if (!isVisible) {
        clearInterval(intervalId.current);
        intervalId.current = null;
        return;
      }

      if (intervalId.current) {
        return;
      }

      intervalId.current = setInterval(async () => {
        // const canvas = document.createElement('canvas');
        // canvas.width = 640;
        // canvas.height = 480;
        // var ctx = canvas.getContext('2d');
        // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        //
        // //convert to desired file format
        // // const dataURI = canvas.toDataURL('image/jpeg'); // can also use 'image/png'
        // canvas.toBlob(async (blob) => {
        //   const imageUuid = nanoid(12);
        //   const imageFilename = `${imageUuid}.png`;
        //   const formData = new FormData();
        //
        //   formData.append("file", blob, imageFilename);
        //   await axios.post(
        //     "https://image-upload-service-lihmnlumxq-no.a.run.app/upload-file-to-cloud-storage",
        //     formData
        //   );
        // });
        //
        const detection = await faceapi.detectSingleFace(
          video,
          new faceapi.TinyFaceDetectorOptions({
            scoreThreshold: 0.3,
            inputSize: 320,
          }),
        );

        if (detection) {
          try {
            sendLog(
              `Face detected ${JSON.stringify(detection)} on player ${player.bsPlayerId}`,
            );
            isVisible && setActiveSlide(1);
          } catch (error) {
            sendLog(`Error parsing face detection`);
          }
        } else {
          sendLog(`No face detected`);
        }
      }, 4000);
    },
    [setActiveSlide, player.bsPlayerId, isVisible, sendLog],
  );

  async function loadFaceApiModels() {
    await faceapi.nets.tinyFaceDetector.loadFromUri("/face-api/models");
    await faceapi.nets.faceLandmark68Net.loadFromUri("/face-api/models");
    await faceapi.nets.faceRecognitionNet.loadFromUri("/face-api/models");
    await faceapi.nets.faceExpressionNet.loadFromUri("/face-api/models");
  }

  const init = useCallback(async () => {
    const video = videoRef.current;

    await loadFaceApiModels();
    await startCameraVideo(video);
    await tracking(video);
  }, [tracking]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div style={style}>
      <video ref={videoRef} style={style} />
    </div>
  );
}

export default FaceDetectionElement;
