import { getTextContent } from "../helpers";

import "./ButtonElement.css";

function ButtonElement({ element, setActiveSlide, creativeState = {} }) {
  const { style, targetSlide, config = {} } = element;

  const { state = {}, icon } = config;

  const { disabled: stateDisabled } = state;

  const disabled = stateDisabled !== undefined && !creativeState[stateDisabled]

  const text = getTextContent(element);

  function onClick (targetSlide) {
    if (targetSlide) {
      return function () {
        setActiveSlide(targetSlide)
      }
    }
  }

  return <button className="button" style={{ ...style, position: "static" }} onClick={onClick(targetSlide)} disabled={disabled}>
    {text}
    <span className="material-symbols-outlined" style={{ ...style, top: 0, left: 0, margin: "24px 30px", height: "auto", width: "auto" }} >photo_camera</span>
  </button>;
}

export default ButtonElement;
