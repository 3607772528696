import axios from "axios";
import { nanoid } from "nanoid";

export function takeScreenshot (canvasClass, onDone, rotation) {
  const baseCanvas = document.querySelector(canvasClass);
  const canvas = document.querySelector('.face-tracker-canvas');
  const oCw = canvas.width;
  const oCh = canvas.height;
  const ctx = canvas.getContext('2d');
  let rotating;
  let myImageData;

  if (!rotating) {
    rotating = true;            
    myImageData = new Image();
    myImageData.src = canvas.toDataURL();

   myImageData.onload = function () {
        // reset the canvas with new dimensions
        canvas.width = 1080;
        canvas.height = 1920;
        const cw = canvas.width;
        const ch = canvas.height;

        ctx.save();
        // translate and rotate
        if (rotation === 90) {
          ctx.translate(cw, 0);
          ctx.rotate((90 * Math.PI) / 180);
        } else {
          ctx.translate(0, ch);
          ctx.rotate((- 90 * Math.PI) / 180);
        }

        // draw the previows image, now rotated
        ctx.drawImage(myImageData, 0, -250);         
        // ctx.drawImage(myImageData, -myImageData.width / 2, -myImageData.height / 2);    
        ctx.restore();

        // clear the temporary image
        myImageData = null;

        rotating = false;
        ctx.drawImage(baseCanvas, 0, 0);

        canvas.toBlob(async (blob) => {
          canvas.width = oCw;
          canvas.height = oCh;
          const imageUuid = nanoid(12);
          const imageFilename = `${imageUuid}.png`;
          const formData = new FormData();

          formData.append('file', blob, imageFilename);
          axios.post(
            'https://image-upload-service-lihmnlumxq-no.a.run.app/upload-file-to-cloud-storage',
            formData
          ).then(response => onDone(response.data.publicUrl));
        });
        
    }
  }
};